<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >

      <!-- details tab -->
      <tab-content
        title="Tournament Details"
        :before-change="tournamentDetailsForm"
      >
        <validation-observer
          ref="tournamentDetailsRules"
          tag="form"
        >
          <b-row>
            <b-col
              md="6"
            >
              <div class="mt-2">
                {{ orgName }}
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Game"
                label-for="game"
              >
                <b-form-input
                  readonly
                  value="Battlegrounds Mobile India"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Tournament Name"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tournament Name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="orgName+' Tournament'"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <div class="mt-2">
                <b-form-checkbox
                  v-model="form.prize_attributes.scrims"
                  switch
                  inline
                >
                  Scrims
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="form.private"
                  switch
                  inline
                >
                  Private Tournament
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Registration Starts at"
                label-for="registration_starts_at"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Registration Starts at"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.registration_starts_at"
                    :config="{ dateFormat: 'Y-m-d H:i:s', minDate: new Date().getTime(), enableTime: true, }"
                    :state="errors.length > 0 ? false:null"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Registration Ends at"
                label-for="registration_ends_at"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Registration Ends at"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.registration_ends_at"
                    :config="{ dateFormat: 'Y-m-d H:i:s', minDate: form.registration_starts_at, enableTime: true, }"
                    :state="errors.length > 0 ? false:null"
                    class="form-control"
                    :disabled="!form.registration_starts_at"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tournament Starts at"
                label-for="tournament_starts_at"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tournament Starts at"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.tournament_starts_at"
                    :config="{ dateFormat: 'Y-m-d H:i:s', minDate: form.registration_ends_at, enableTime: true, }"
                    :state="errors.length > 0 ? false:null"
                    class="form-control"
                    :disabled="!form.registration_ends_at"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tournament Ends at"
                label-for="tournament_ends_at"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tournament Ends at"
                  rules="required"
                >
                  <flat-pickr
                    v-model="form.tournament_ends_at"
                    :config="{ dateFormat: 'Y-m-d H:i:s', minDate: form.tournament_starts_at, enableTime: true, }"
                    :state="errors.length > 0 ? false:null"
                    class="form-control"
                    :disabled="!form.tournament_starts_at"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Map"
                rules="required"
              >
                <b-form-group
                  label="Map"
                  label-for="add-view"
                >
                  <v-select
                    v-model="form.attributes.map_id"
                    :options="mapOption"
                    :reduce="label => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-view"
                    multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="View"
                rules="required"
              >
                <b-form-group
                  label="View"
                  label-for="add-view"
                >
                  <v-select
                    v-model="form.view_id"
                    :options="viewOption"
                    :reduce="label => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-view"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Mode"
                rules="required"
              >
                <b-form-group
                  label="Mode"
                  label-for="add-mode"
                >
                  <v-select
                    v-model="form.mode_id"
                    :options="modeOption"
                    :reduce="label => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-mode"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
            >
              <div class="mt-2">
                <b-form-checkbox
                  v-model="form.attributes.allow_substitutes"
                  switch
                  inline
                >
                  Allow Substitues
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="form.attributes.emulator"
                  switch
                  inline
                >
                  Is Emulator Allowed?
                </b-form-checkbox>
              </div>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- structure tab -->
      <tab-content
        title="Structure"
        :before-change="tournamentStructureForm"
      >
        <validation-observer
          ref="tournamentStructureRules"
          tag="form"
        >

          <b-row>
            <b-col
              sm="6"
            >
              <b-form-group
                label="Total Slots ( Teams)"
                label-for="total_teams"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Total Slots"
                  rules="required"
                >
                  <b-form-input
                    id="total_teams"
                    v-model="form.attributes.structure.teams"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="6"
            >
              <b-form-group
                label="Number of Rounds ( Stages )"
                label-for="total_rounds"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Number of Rounds ( Stages )"
                  rules="required"
                >
                  <b-form-input
                    id="total_rounds"
                    v-model="form.attributes.structure.stages_count"
                    type="number"
                    min="0"
                    max="10"
                    :state="errors.length > 0 ? false:null"
                    @input="updateStages"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-row
                v-for="(stage_data,i) in form.attributes.structure.stages"
                :key="i"
                class=" py-1 mx-0 mb-1 border border-secondary rounded-lg"
              >
                <b-col
                  sm="12"
                >
                  <h5>Round ( Stage ) : {{ i+1 }}</h5>
                </b-col>
                <b-col
                  sm="6"
                >
                  <b-form-group
                    label="Name"
                    label-for="stage_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="stage_name"
                        v-model="stage_data.name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="e.g. Round 1 / Qualifiers / Finals"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="6"
                >
                  <b-form-group
                    label="Slots / Squads per Group"
                    label-for="stage_num_slots"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Slots / Squads per Group"
                      :rules="'required|max_value:'+getMaxSlots(i)"
                    >
                      <b-form-input
                        id="stage_num_slots"
                        v-model="form.attributes.structure.stages[i].max_teams_per_group"
                        :state="errors.length > 0 ? false:null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="3"
                >
                  <b-form-group
                    label="No. of Groups"
                    label-for="stage_num_groups"
                  >
                    <div
                      v-if="form.attributes.structure.stages[i].max_teams_per_group"
                      class="mt-1"
                    >
                      {{ getNumberOfGroups(i) }}
                    </div>
                    <div v-else>
                      --
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="6"
                >
                  <b-form-group
                    label="Top Group Slots / Squads promoted to next Round"
                    label-for="stage_next_match_teams"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Top Group Slots / Squads promoted to next Round"
                      rules="required"
                    >
                      <b-form-input
                        id="stage_next_match_teams"
                        v-model="form.attributes.structure.stages[i].number_of_teams_for_next_stage"
                        :state="errors.length > 0 ? false:null"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="3"
                >
                  <b-form-group
                    label="Matches per Group"
                    label-for="stage_matches"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Matches per Group"
                      rules="required"
                    >
                      <b-form-input
                        id="stage_matches"
                        v-model="form.attributes.structure.stages[i].number_of_matches"
                        :state="errors.length > 0 ? false:null"
                        type="number"
                        min="1"
                        max="10"
                        @input="updateMatches(i)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="form.attributes.structure.stages[i].number_of_matches"
                  sm="12"
                >
                  <b-row
                    v-for="(match_data,m) in form.attributes.structure.stages[i].matches"
                    :key="m"
                    class=" py-1 mx-0 mb-1 border border-primary rounded-lg"
                  >
                    <b-col
                      sm="12"
                    >
                      <h5>Round {{ i+1 }}, Group {{ match_data.group }} - Match {{ m+1 }}</h5>
                    </b-col>
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Mode"
                        rules="required"
                      >
                        <b-form-group
                          label="Mode"
                          label-for="add-mode"
                        >
                          <v-select
                            v-model="match_data.map_id"
                            :options="mapOption"
                            :reduce="label => label.code"
                            label="label"
                            :clearable="true"
                            input-id="change-mode"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Match Starts at"
                        label-for="tournament_starts_at"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Match Starts at"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="match_data.match_at"
                            :config="{ dateFormat: 'Y-m-d H:i:s', minDate: form.tournament_starts_at, enableTime: true, }"
                            :state="errors.length > 0 ? false:null"
                            class="form-control"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="form.attributes.structure.stages[i].number_of_matches"
                  sm="12"
                  class="d-none"
                >
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="form.attributes.structure.stages[i].is_final"
                      switch
                      inline
                      @input="checkKills"
                    >
                      Final
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
            >
              <hr>
            </b-col>
            <b-col
              sm="6"
              md="3"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="form.prize_attributes.kills_enabled"
                  switch
                  inline
                  @input="checkKills"
                >
                  Enable Kill Points
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col
              v-if="form.prize_attributes.kills_enabled"
              sm="6"
              md="3"
            >
              <b-form-group
                label="Points per kill"
                label-for="points_per_kill"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Points per kill"
                  rules="required"
                >
                  <b-form-input
                    id="points_per_kill"
                    v-model="form.prize_attributes.points_per_kill"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              class="mb-2"
            />
            <b-col
              sm="6"
              md="3"
              class="mb-2"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="form.prize_attributes.position_enabled"
                  switch
                  inline
                  @input="checkPosition"
                >
                  Enable Position Points
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col
              v-if="form.prize_attributes.position_enabled"
              sm="12"
            >
              <b-row>
                <b-col
                  v-for="(points, n) in form.prize_attributes.position_points"
                  :key="n"
                  sm="4"
                  md="3"
                >
                  <b-form-group
                    :label="(n+1) + getSuffix(n+1) +' Position'"
                    label-for="points_per_kill"
                  >
                    <validation-provider
                      #default="{ errors }"

                      :name="(n+1) + getSuffix(n+1) +' Position'"
                      :rules="'required|max_value:'+getMaxPositionPoints(n)"
                    >

                      <b-input-group>
                        <b-form-input
                          id="points_per_kill"
                          v-model="form.prize_attributes.position_points[n]"
                          type="number"
                          min="0"
                          :state="errors.length > 0 ? false:null"

                          :placeholder="(n+1) + getSuffix(n+1) +' Position Points'"
                        />
                        <b-input-group-append>
                          <b-button
                            v-if="n || ( !n && form.prize_attributes.position_points.length > 1)"
                            v-b-tooltip.hover.top="' Remove'"
                            variant="outline-primary"
                            class="btn-danger px-1"
                            @click="removePositionPoint(n)"
                          >
                            <feather-icon
                              icon="MinusCircleIcon"
                            />
                          </b-button>
                          <b-button
                            v-if="n == form.prize_attributes.position_points.length-1"
                            v-b-tooltip.hover.top="'Add '"
                            variant="outline-primary"
                            class="btn-success px-1"
                            @click="addPositionPoint(n)"
                          >
                            <feather-icon icon="PlusCircleIcon" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </validation-provider>
                  </b-form-group>

                </b-col></b-row>
            </b-col>

            <b-col
              sm="12"
              class="mb-2"
            />
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- reqards / prizes  -->
      <tab-content
        v-if="!form.prize_attributes.scrims"
        title="Entry Fee & Rewards"
        :before-change="tournamentPrizeForm"
      >
        <validation-observer
          ref="tournamentPrizeRules"
          tag="form"
        >

          <b-row>

            <b-col
              sm="12"
              class="mb-2"
            >
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-model="form.paid_entry"
                  switch
                  inline
                  @input="checkPaidEntry"
                >
                  Paid Entry
                </b-form-checkbox>
              </div>
            </b-col>
            <b-col
              v-if="form.paid_entry"
              md="6"
            >
              <b-form-group
                label="Seat Cost (per Player)"
                label-for="entry-cost"
              >
                <b-input-group
                  prepend="₹"
                >
                  <b-form-input
                    id="entry-cost"
                    v-model="form.prize_attributes.entry_cost"
                    type="number"
                    min="0"
                    name="entry_cost"
                    placeholder="Entry Cost"
                    @input="calculateSlotCost"
                  />
                </b-input-group>

              </b-form-group>
            </b-col>

            <b-col
              v-if="form.paid_entry"
              md="6"
            >
              <b-form-group
                v-if="form.prize_attributes.entry_cost > 0"
                label="Entry / Slot Cost"
              >
                <span class="font-weight-bolder h5">{{ form.prize_attributes.slot_cost | currency }} </span>
                <small>( {{ form.prize_attributes.entry_cost | currency }} x {{ form.prize_attributes.seats }} )</small>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Reward Type"
                rules="required"
              >
                <b-form-group
                  label="Reward Type"
                  label-for="add-price-type"
                >
                  <v-select
                    v-model="form.prize_attributes.type"
                    :options="priceTypeOption"
                    :reduce="label => label.code"
                    label="label"
                    :clearable="true"
                    input-id="change-priceType"
                    :disabled="!form.paid_entry"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="12"
              class="mt-2"
            />
            <b-col
              md="6"
            >
              <b-form-group
                label="Match Rewards"
                label-for="match-price"
              >
                <div
                  v-for="(input,k) in match_rewards"
                  :key="k"
                  class="form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="(k+1)+' Place Reward / Prize'"
                    :rules="'required|max_value:'+getMaxPrice(k)"
                  >
                    <b-input-group
                      prepend="₹"
                    >
                      <b-form-input
                        id="match-price"
                        v-model="input.price"
                        type="number"
                        min="0"
                        :max="getMaxPrice(k)"
                        :name="'match_price['+(k+1)+']'"
                        :placeholder="(k+1)+' Place Reward / Prize'"
                      />
                      <b-input-group-append>
                        <b-button
                          v-if="k || ( !k && match_rewards.length > 1)"
                          v-b-tooltip.hover.top="' Remove Reward'"
                          variant="outline-primary"
                          class="btn-danger"
                          @click="remove(k)"
                        >
                          <feather-icon
                            icon="MinusCircleIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="k == match_rewards.length-1"
                          v-b-tooltip.hover.top="'Add Reward'"
                          variant="outline-primary"
                          class="btn-success"
                          @click="add(k)"
                        >
                          <feather-icon icon="PlusCircleIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              class="mt-2"
            >
              <div v-if="form.paid_entry">
                Maximum Expected Entry : <span class="h5 font-weight-bolder">{{ form.prize_attributes.max_players * form.prize_attributes.entry_cost | currency }}</span><br>
              </div>
              <div>
                Total Rewards  : <span
                  class="h5 font-weight-bolder"
                  :class="{ 'text-warning' : getRemainingBalance() < 0}"
                >{{ getSumOfRewards() | currency }}</span></div>
              <div v-if="form.paid_entry">
                Approximate Balance  : <span
                  class="h5 font-weight-bolder text-success"
                  :class="{ 'text-danger' : getRemainingBalance() < 0}"
                >{{ getRemainingBalance() | currency }}</span></div>
            </b-col>

            <b-col
              v-if="form.paid_entry"
              md="12"
              class="mt-2 d-none [todo]"
            >
              quick options
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- rules -->
      <tab-content
        title="Rules & Points"
        :before-change="tournamentRulesSocialForm"
      >
        <validation-observer
          ref="tournamentRulesSocialRules"
          tag="form"
        >

          <b-row>
            <b-col class="md-8" />
            <b-col class="md-4">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchbar"
                  v-model="rulesSearchQuery"
                  placeholder="Search Rule..."
                />
              </b-input-group>
            </b-col>
          </b-row>

          <section
            id="faq-tabs"
            class="mt-2 mb-2"
          >
            <div v-if="rulesData.length">
              <b-tabs
                vertical
                content-class="col-12 col-md-8 col-lg-9"
                pills
                nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
                nav-class="nav-left"
              >

                <b-tab
                  v-for="(obj, index) in rulesData"
                  :key="index"
                  :active="!index"
                >

                  <!-- title -->
                  <template #title>
                    <span class="font-weight-bold">{{ obj.title }}</span>
                  </template>

                  <div v-if="obj">
                    <div class="d-flex align-items-center mt-1 mb-2">
                      <div>
                        <h4 class="mb-0">
                          {{ obj.title }}
                        </h4>
                      </div>
                    </div>
                    <b-list-group>
                      <b-list-group-item
                        v-for="(data,indexes) in obj.descriptions"
                        :key="indexes"
                      >
                        <b-form-checkbox
                          v-model="form.rules_id"
                          :value="data.id"
                        >{{ data.description }}
                        </b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
            <div
              v-else
              class="text-center"
            >
              <span>No Rule Found</span>
            </div>
          </section>

        </validation-observer>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import flatPickr from 'vue-flatpickr-component'
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  VBTooltip,
  BButton,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormInput,
  BTab,
  BTabs,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { max_value } from 'vee-validate/dist/rules'
import { mapGetters } from 'vuex'

extend('max_value', max_value)
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,

    BInputGroupPrepend,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BFormCheckbox,
    BTab,
    BTabs,
    BListGroup,
    BListGroupItem,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      stage: {
        name: '',
        stage: '',
        number_of_groups: '',
        number_of_matches: '',
        max_teams_per_group: '',
        number_of_teams_for_next_stage: '',
      },
      form: {
        organization_id: '',
        name: '',
        registration_starts_at: '',
        registration_ends_at: '',
        tournament_starts_at: '',
        tournament_ends_at: '',
        private: false,
        map_id: '',
        mode_id: '',
        view_id: '',
        attributes: {
          media: {
            image: '',
            video: '',
            youtube: '',
          },
          players: {
            total: 0,
          },
          structure: {
            teams: '',
            stages_count: '',
            stages: [],
          },
          score_reporting: {
            enabled: true,
            require_proof: true,
            require_approval: true,
          },
          allow_substitutes: true,
          emulator: false,
        },

        paid_entry: false,
        prize_attributes: {
          scrims: false,
          type: 'fixed',
          slot_cost: 0,
          entry_cost: 0,
          max_players: 0,
          kills_enabled: true,
          points_per_kill: 1,
          position_enabled: false,
          position_points: [30, 22, 19, 16, 14, 12, 10, 8, 7, 6, 5, 4, 3, 2, 1],
          rewards: [],
          prize_pool: 0,
        },

        rules_id: [],
      },

      organizationOption: [],
      tournamentOption: [],
      mapOption: [],
      modeOption: [],
      viewOption: [],
      priceTypeOption: [],
      tournamentStatusOption: [],
      position_points: [30, 22, 19, 16, 14, 12, 10, 8, 7, 6, 5, 4, 3, 2, 1],

      match_rewards: [{
        price: '',
      }],
      slotCost: 0,
      entry_cost: 0,
      max_players: 0,
      rules_id: [],
      rulesData: [],
      rulesSearchQuery: '',

    }
  },
  computed: {
    ...mapGetters({
      getUserRole: 'app/getUserRole',
      orgName: 'app/getOrgName',
    }),
  },
  watch: {
    rulesSearchQuery: {
      immediate: true,
      handler() {
        this.fetchRules()
      },
    },
  },
  created() {
    this.$http.get('/list/getUserOrganization')
      .then(res => {
        this.organizationOption = res.data.data
        if (res.data.success) {
          this.form.organization_id = ''
          const organizationSelectedLocal = localStorage.getItem('organizationSelected')
          if (organizationSelectedLocal !== 'null') {
            this.form.organization_id = parseInt(organizationSelectedLocal, 10)
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Organization fetching failed',
              icon: 'BellIcon',
              text: 'Oops! Something Went Wrong',
              variant: 'danger',
            },
          }, {
            timeout: 3000,
          })
        }
      })
      .catch(error => {
        // Do something on error
        // console.log(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Organization fetching failed',
            icon: 'BellIcon',
            text: 'Oops! Something Went Wrong',
            variant: 'danger',
          },
        }, {
          timeout: 3000,
        })
      })
    this.$http.get('/list/getMatchStatus')
      .then(res => {
        this.matchStatusOption = res.data.data
      })
      .catch(error => {
        // console.log(error)
      })
    this.$http.get('/list/getPriceType')
      .then(res => {
        this.priceTypeOption = res.data.data
      })
      .catch(error => {
        // console.log(error)
      })
    this.$http.get('/list/getPubgMaps')
      .then(res => {
        this.mapOption = res.data.data
      })
    this.$http.get('/list/getPubgModes')
      .then(res => {
        this.modeOption = res.data.data
      })
    this.$http.get('/list/getPubgViews')
      .then(res => {
        this.viewOption = res.data.data
      })
    // this.form = {
    //   name: 'test tournament',
    //   registration_starts_at: new Date().fp_incr(1),
    //   registration_ends_at: new Date().fp_incr(2),
    //   tournament_starts_at: new Date().fp_incr(3),
    //   tournament_ends_at: new Date().fp_incr(4),
    //   private: false,
    //   view_id: 1,
    //   map_id: 1,
    //   mode_id: 3,
    //   paid_entry: false,
    //   attributes: {
    //     media: {
    //       image: '',
    //       video: '',
    //       youtube: '',
    //     },
    //     players: {
    //       total: 800,
    //       per_match: 80,
    //     },
    //     structure: {
    //       teams: 120,
    //       stages_count: 1,
    //       stages: [
    //         {
    //           name: 'test tournament',
    //           stage: '1',
    //           number_of_groups: '1',
    //           number_of_matches: '1',
    //           max_teams_per_group: '120',
    //           number_of_teams_for_next_stage: '0',
    //           matches: [
    //             {
    //               map_id: 1,
    //               match_at: '2022-01-05 15:00:0',
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //     score_reporting: {
    //       enabled: true,
    //       require_proof: true,
    //       require_approval: true,
    //     },
    //     allow_substitutes: true,
    //     emulator: false,
    //   },
    //   prize_attributes: {
    //     scrims: false,
    //     type: 'fixed',
    //     slot_cost: 0,
    //     entry_cost: 0,
    //     kills_enabled: true,
    //     points_per_kill: 1,

    //     position_enabled: false,
    //     position_points: [30, 22, 19, 16, 14, 12, 10, 8, 7, 6, 5, 4, 3, 2, 1],
    //     max_players: 60,
    //     rewards: {
    //       1: {
    //         title: 1,
    //         max_prize: 500,
    //         percentage: 28,
    //       },
    //       2: {
    //         title: 2,
    //         max_prize: 250,
    //         percentage: 14,
    //       },
    //       3: {
    //         title: 3,
    //         max_prize: 150,
    //         percentage: 9,
    //       },
    //       4: {
    //         title: 4,
    //         max_prize: 100,
    //         percentage: 6,
    //       },
    //       5: {
    //         title: 5,
    //         max_prize: 70,
    //         percentage: 4,
    //       },
    //     },
    //     prize_pool: 1800,
    //   },
    //   rules_id: [],
    // }

    this.match_rewards = [{
      price: '111',
    }]
  },
  methods: {

    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Setting up the playground for your Tournament ...',
          icon: 'ActivityIcon',
          variant: 'warning',
        },
      })
      const organisationId = localStorage.getItem('organizationSelected')
      if (organisationId > 0) {
        this.form.organization_id = organisationId
      }
      this.$http
        .post('/organizer/tournament/add', this.form)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tournament Added',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'tournaments' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Add Tournament Failed',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    },

    tournamentDetailsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.tournamentDetailsRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    tournamentStructureForm() {
      return new Promise((resolve, reject) => {
        this.$refs.tournamentStructureRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    tournamentPrizeForm() {
      if (this.getRemainingBalance() < 0 && this.form.paid_entry) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rewards Error',
            icon: 'BellIcon',
            variant: 'danger',
            text: 'Total Reward is exceeding Collection',
          },
        })
        return false
      }
      return new Promise((resolve, reject) => {
        this.$refs.tournamentPrizeRules.validate().then(success => {
          if (success) {
            this.matchPrizeCalculation()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    tournamentRulesSocialForm() {
      return new Promise((resolve, reject) => {
        this.$refs.tournamentRulesSocialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    // structure

    updateStages() {
      if (this.form.attributes.structure.stages_count < this.form.attributes.structure.stages.length) {
        this.form.attributes.structure.stages.splice(this.form.attributes.structure.stages_count, this.form.attributes.structure.stages.length - this.form.attributes.structure.stages_count)
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = this.form.attributes.structure.stages.length; i < this.form.attributes.structure.stages_count; i++) {
          this.form.attributes.structure.stages.push({
            name: '',
            stage: '',
            number_of_groups: '',
            number_of_matches: '',
            max_teams_per_group: '',
            number_of_teams_for_next_stage: '',
          })
        }
      }
    },
    getNumberOfGroups(i) {
      this.form.attributes.structure.stages[i].stage = i + 1
      let teamsTotal = this.form.attributes.structure.teams
      if (i) {
        teamsTotal = this.form.attributes.structure.stages[i - 1].number_of_teams_for_next_stage * this.form.attributes.structure.stages[i - 1].number_of_groups
      }
      if (this.form.attributes.structure.stages[i].max_teams_per_group) {
        this.form.attributes.structure.stages[i].number_of_groups = Math.ceil(teamsTotal / this.form.attributes.structure.stages[i].max_teams_per_group)
      }
      if (this.form.attributes.structure.stages[i].number_of_matches > 0) {
        this.updateMatches(i)
      }
      return this.form.attributes.structure.stages[i].number_of_groups
    },
    updateMatches(i) {
      let groupsCount = this.form.attributes.structure.stages[i].number_of_groups
      if (!groupsCount) {
        groupsCount = 0
      }
      const totalMatches = groupsCount * this.form.attributes.structure.stages[i].number_of_matches
      if (!this.form.attributes.structure.stages[i].matches) {
        this.form.attributes.structure.stages[i].matches = []
      }
      if (totalMatches < this.form.attributes.structure.stages[i].matches.length) {
        this.form.attributes.structure.stages[i].matches.splice(totalMatches, this.form.attributes.structure.stages[i].matches.length - totalMatches)
      } else {
        let charcode = 65
        for (let j = this.form.attributes.structure.stages[i].matches.length; j < totalMatches; j += 1) {
          this.form.attributes.structure.stages[i].matches.push({
            map_id: '',
            match_at: '',
            group: String.fromCharCode(charcode),
            stage: i + 1,
          })
          if ((j + 1) % this.form.attributes.structure.stages[i].number_of_matches === 0) {
            charcode += 1
          }
        }
      }
    },

    getMaxSlots(i) {
      let maxSLots = this.form.attributes.structure.teams

      if (i) {
        maxSLots = this.form.attributes.structure.stages[i - 1].number_of_teams_for_next_stage * this.form.attributes.structure.stages[i - 1].number_of_groups
      }
      return maxSLots
    },
    getSuffix(n) {
      return n < 11 || n > 13
        ? ['st', 'nd', 'rd', 'th'][Math.min((n - 1) % 10, 3)]
        : 'th'
    },
    getMaxPositionPoints(n) {
      let maxPositionPoint = this.form.prize_attributes.position_points[n - 1]
      if (n === 0) {
        maxPositionPoint = this.form.prize_attributes.position_points[n] + 1
      }
      return maxPositionPoint
    },
    addPositionPoint() {
      this.form.prize_attributes.position_points.push('')
    },
    removePositionPoint(index) {
      this.form.prize_attributes.position_points.splice(index, 1)
    },

    checkKills() {
      if (this.form.prize_attributes.kills_enabled) {
        this.form.prize_attributes.points_per_kill = 1
      } else {
        this.form.prize_attributes.points_per_kill = 0
      }
    },
    checkPosition() {
      if (this.form.prize_attributes.position_enabled) {
        this.form.prize_attributes.position_points = this.position_points
      } else {
        this.form.prize_attributes.position_points = []
      }
    },

    // Prizes
    checkPaidEntry() {
      if (this.form.paid_entry) {
        this.form.prize_attributes.type = ''

        this.match_rewards = [{
          price: '',
        }]
        this.form.prize_attributes.entry_cost = ''
      } else {
        this.form.prize_attributes.type = 'fixed'
        this.form.prize_attributes.max_players = 0
      }
    },

    calculateSlotCost() {
      this.$http.get('/match/getSlotPrize', { params: { entry_cost: this.form.prize_attributes.entry_cost, mode_id: this.form.mode_id } })
        .then(res => {
          this.form.prize_attributes.slot_cost = res.data.data.entry
          this.form.prize_attributes.seats = res.data.data.seats

          this.form.prize_attributes.max_players = this.form.attributes.structure.teams * res.data.data.seats
        })
    },
    add() {
      this.match_rewards.push({
        price: '',
      })
    },
    remove(index) {
      this.match_rewards.splice(index, 1)
    },
    getSumOfRewards() {
      return this.match_rewards.reduce((a, b) => {
        let c = 0
        if (b.price !== '') {
          c = b.price
        }
        return parseInt(a, 10) + parseInt(c, 10)
      }, 0)
    },
    getRemainingBalance() {
      let sumOfRewards = 0
      if (this.getSumOfRewards()) {
        sumOfRewards = this.getSumOfRewards()
      }
      return this.getMaximumCollection() - sumOfRewards
    },
    getMaximumCollection() {
      return this.form.prize_attributes.entry_cost * this.form.prize_attributes.max_players
    },
    matchPrizeCalculation() {
      this.form.prize_attributes.prize_pool = this.getSumOfRewards()
      const maximumCollection = this.getMaximumCollection()
      this.form.attributes.status = 2
      this.form.prize_attributes.rewards = []
      this.match_rewards.map((value, key) => {
        this.form.prize_attributes.rewards[`${key + 1}`] = { title: key + 1, max_prize: value.price, percentage: (value.price / maximumCollection) * 100 }
        return true
      })
      // form.prize_attributes.max_players * form.prize_attributes.entry_cost
    },
    getMaxPrice(k) {
      let maxPrice = this.form.prize_attributes.entry_cost * this.form.prize_attributes.max_players
      if (!this.form.paid_entry) {
        maxPrice = this.match_rewards[0].price + 1
      }
      if (k) {
        maxPrice = this.match_rewards[k - 1].price
      }
      return maxPrice
    },
    // rules

    fetchRules() {
      this.$http.get('/rule/list/form', { params: { q: this.rulesSearchQuery } })
        .then(res => {
          this.rulesData = res.data.data
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/pages/page-faq.scss';
</style>
